import {
  get,
  post,
  put,
  patch,
  deleteRequest,
} from '../../../../../../../service/api';

export const getTournamentList = async (payload: {
  offset: number;
  limit: number;
  text: string;
  merchantName: string;
}) => {
  try {
    const { text, offset, limit, merchantName } = payload;
    const params = { offset, limit, text };
    const result = await get(
      `/merchants/${merchantName}/games`,
      params,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getEnrollmentList = async (payload: {
  offset: number;
  limit: number;
  text: string;
  merchantName: string;
}) => {
  try {
    const { text, offset, limit, merchantName: assoMerchantName } = payload;
    const params = { offset, limit, assoMerchantName, text, type: 'archery' };
    const result = await get(`/association-enrolls`, params, {}, {});

    return result;
  } catch (error) {
    throw error;
  }
};

export const getEnrollmentDetailsById = async (payload: { id: string }) => {
  try {
    const { id } = payload;
    const result: any = await get(`/association-enrolls/${id}`, {}, {});

    return result;
  } catch (error) {
    throw error;
  }
};

export const getEnrollmentResultPlayersSummaryById = async (payload: {
  enrollmentId: string;
}) => {
  try {
    const { enrollmentId } = payload;
    const result: any = await get(
      `/association-enrolls/${enrollmentId}/players/summary`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getEnrollmentPlayersUnclassified = async (payload: {
  enrollmentId: string;
}) => {
  try {
    const result = await get(
      `/association-enrolls/${payload.enrollmentId}/players/unclassified`,
      {},
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const postCreateGame = async (
  merchantName: string,
  payload: {
    title: string;
    enrollmentId: string;
    scoreRecordingType: string;
    scoreVerificationMethod: string;
    demandIds: string[];
    targetNums: string[];
    segments: object[];
  }
) => {
  try {
    const result = await post(`/merchants/${merchantName}/games`, payload, {});

    return result;
  } catch (error) {
    throw error;
  }
};

export const getGameDetail = async (merchantName: string, gameId: string) => {
  try {
    const result: any = await get(
      `/merchants/${merchantName}/games/${gameId}`,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const putGameDetail = async (merchantName: string, payload: object) => {
  try {
    const { gameId }: any = payload || {};

    const result: any = await put(
      `/merchants/${merchantName}/games/${gameId}`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const resetGame = async (merchantName: string, payload: object) => {
  try {
    const { gameId }: any = payload || {};

    const result: any = await patch(
      `/merchants/${merchantName}/games/${gameId}/reset`,
      payload,
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const patchGameStatus = async (
  merchantName: string,
  gameId: string | null,
  status: string
) => {
  try {
    const result: any = await patch(
      `/merchants/${merchantName}/games/${gameId}/status`,
      { status },
      {}
    );

    return result;
  } catch (error) {
    throw new Error('patch game status failed');
  }
};

export const patchSegmentStatus = async (
  merchantName: string,
  gameId: string | null,
  segmentId: string | null,
  status: string
) => {
  try {
    const result: any = await patch(
      `/merchants/${merchantName}/games/${gameId}/segments/${segmentId}/status`,
      { status },
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const patchGameNextSegment = async (
  merchantName: string,
  gameId: string | null
) => {
  try {
    const result: any = await patch(
      `/merchants/${merchantName}/games/${gameId}/next-segment`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const deleteRequestGameDetail = async (
  merchantName: string,
  gameId: string | null
) => {
  try {
    const result: any = await deleteRequest(
      `/merchants/${merchantName}/games/${gameId}`,
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getTargetsSettingExcelExport = async (
  merchantName: string,
  gameId: string
) => {
  try {
    const result: any = await get(
      `/merchants/${merchantName}/games/${gameId}/export/target-settings`,
      {},
      {
        responseType: 'blob',
      }
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getCurrentStageScoreSheetExport = async (
  merchantName: string,
  gameId: string,
  segmentId: string
) => {
  try {
    const result: any = await get(
      `/merchants/${merchantName}/games/${gameId}/segments/${segmentId}/scorecards`,
      {},
      {
        responseType: 'blob',
      }
    );

    return result;
  } catch (error) {
    throw error;
  }
};

export const getTournamentLegends = async (enrollmentId: string | null) => {
  try {
    const result = await get(
      `/association-enrolls/${enrollmentId}/tournaments-preview`,
      {},
      {},
      {}
    );

    return result;
  } catch (error) {
    throw error;
  }
};
