import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal, Radio, Select, Tag } from 'antd';
import {
  InboxOutlined,
  RightOutlined,
  ExclamationCircleOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
  SyncOutlined,
} from '@ant-design/icons';
import {
  getHasSelectedEnrollmentId,
  getGameDetailSelectedGameId,
  getGameDetailTitle,
  getGameDetailAddress,
  getGameDetailEnrollmentId,
  getGameDetailEnrolledCount,
  getGameDetailShowDetailModal,
  getIsGameDeletable,
  getIsGameJudgesEditable,
  getEnrollmentSelectList,
  getGameDetailMultiPlayerTeamCount,
  getEnrollmentPlayersNumberUnclassified,
  getGameDetailScoreRecordingType,
  getGameDetailScoreVerificationMethod,
  getEmployeeCheckBoxList,
  getGameDetailDemandIds,
  getTargetsSetLengthForTournament,
  getIsTargetsSettingEnabled,
  getDisplayEnrollmentSelectList,
  getGameDetailEventScheduleType,
  getGameDetailIsHidden,
  getIsGameHidable,
} from '../../redux/selectors';
import { actions as tournamentListActions } from '../../redux';
import {
  loadEmployeeList,
  loadEnrollmentList,
  deleteGame,
  loadEnrollmentDetailsById,
  loadGameById,
  updateGameDetail,
  loadEnrollmentPlayersUnclassified,
  loadGameDetailRequestDetailModalHidden,
  setIsGameHidden,
  syncEnrollmentPlayersToGame,
} from '../../redux/thunks';
import './TournamentDetailModal.less';
import JudgesSelectionModal from '../JudgesSelectionModal';

let timer: any = null;
const TournamentDetailModal = () => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const hasSelectedEnrollmentId = useSelector(getHasSelectedEnrollmentId);
  const enrollmentSelectList = useSelector(getDisplayEnrollmentSelectList);
  const employeeCheckBoxList = useSelector(getEmployeeCheckBoxList);
  const gameId = useSelector(getGameDetailSelectedGameId);
  const title = useSelector(getGameDetailTitle);
  const address = useSelector(getGameDetailAddress);
  const enrollmentId = useSelector(getGameDetailEnrollmentId);
  const enrolledCount = useSelector(getGameDetailEnrolledCount);
  const multiPlayerTeamCount = useSelector(getGameDetailMultiPlayerTeamCount);
  const enrollmentPlayersNumberUnclassified = useSelector(
    getEnrollmentPlayersNumberUnclassified
  );
  const scoreRecordingType = useSelector(getGameDetailScoreRecordingType);
  const eventSchedule = useSelector(getGameDetailEventScheduleType);

  const scoreVerificationMethod = useSelector(
    getGameDetailScoreVerificationMethod
  );
  const demandIds = useSelector(getGameDetailDemandIds);
  const showDetailModal = useSelector(getGameDetailShowDetailModal);
  const isGameDeletable = useSelector(getIsGameDeletable);
  const isGameJudgesEditable = useSelector(getIsGameJudgesEditable);
  const isGameHidable = useSelector(getIsGameHidable);
  const isHidden = useSelector(getGameDetailIsHidden);
  const targetsSetLengthForTournament = useSelector(
    getTargetsSetLengthForTournament
  );
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);
  const isTargetsSet =
    targetsSetLengthForTournament >= enrolledCount ||
    targetsSetLengthForTournament >= multiPlayerTeamCount;
  const resetData = useCallback(() => {
    form.resetFields();
    dispatch(tournamentListActions.resetGameDetailData());
  }, [dispatch, form]);
  const handleSaveGameButtonClick = useCallback(() => {
    try {
      dispatch(updateGameDetail() as any);
    } catch (error) {
      throw error;
    }
  }, [dispatch]);
  const handleDeleteGameButtonClick = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: '确定删除该赛事？',
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: () => {
        dispatch(deleteGame() as any);
        resetData();
        dispatch(loadGameDetailRequestDetailModalHidden() as any);
      },
    });
  }, [dispatch, resetData]);
  const handleHideGameButtonClick = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: `确定在小程序${isHidden ? '显示' : '隐藏'}该赛事？`,
      icon: <ExclamationCircleOutlined />,
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await dispatch(setIsGameHidden(!isHidden) as any);
        resetData();
        dispatch(loadGameDetailRequestDetailModalHidden() as any);
      },
    });
  }, [dispatch, isHidden, resetData]);
  const handleCancelButtonClick = useCallback(() => {
    resetData();
    dispatch(loadGameDetailRequestDetailModalHidden() as any);
  }, [dispatch, resetData]);

  const handleSyncEnrollment = useCallback(() => {
    Modal.confirm({
      centered: true,
      title: `确定同步报名数据？`,
      icon: <ExclamationCircleOutlined />,
      content: '同步报名数据后，您需要重新分配靶位信息',
      okText: '确认',
      cancelText: '取消',
      onOk: async () => {
        await dispatch(syncEnrollmentPlayersToGame() as any);
        resetData();
        dispatch(loadGameDetailRequestDetailModalHidden() as any);
      },
    });
  }, [dispatch, resetData]);
  const handleGameDetailDataChange = (changedValues: any) => {
    dispatch(
      tournamentListActions.loadGameDetailRequestDataUpdated(
        changedValues
      ) as any
    );
  };
  const handleTargetSettingModalButtonClick = useCallback(() => {
    dispatch(
      tournamentListActions.loadGameDetailRequestTargetsSettingModalShown() as any
    );
  }, [dispatch]);

  useEffect(() => {
    if (showDetailModal) {
      dispatch(loadEnrollmentList() as any);
      dispatch(loadEmployeeList() as any);
    }
  }, [showDetailModal, dispatch]);

  useEffect(() => {
    if (gameId && showDetailModal) {
      dispatch(loadGameById(gameId) as any);
    }
  }, [gameId, dispatch, showDetailModal]);

  useEffect(() => {
    if (enrollmentId && !hasSelectedEnrollmentId) {
      dispatch(loadEnrollmentDetailsById(enrollmentId) as any);
    }
  }, [enrollmentId, hasSelectedEnrollmentId, dispatch]);

  useEffect(() => {
    if (enrollmentId) {
      dispatch(loadEnrollmentPlayersUnclassified(enrollmentId) as any);
    }
  }, [dispatch, enrollmentId]);
  const [showJudgeSelectionModal, setShowJudgeSelectionModal] =
    useState<boolean>(false);

  return (
    <Modal
      centered
      maskClosable={false}
      visible={showDetailModal}
      footer={[
        <div className="TournamentDetailModalFooterButtonGroup flex flex-middle">
          {isGameDeletable && (
            <Button
              block
              danger
              ghost
              type="primary"
              className="TournamentDetailModalFooterButton TournamentDetailModalFooterButtonGroup"
              onClick={handleDeleteGameButtonClick}
            >
              删除赛事
            </Button>
          )}
          <Button
            block
            type="primary"
            style={{
              background: '#13c2c2',
              borderColor: '#13c2c2',
            }}
            disabled={!isGameHidable}
            className="TournamentDetailModalFooterButton"
            onClick={handleHideGameButtonClick}
          >
            {isHidden ? <EyeOutlined /> : <EyeInvisibleOutlined />}
            {isHidden ? '在小程序显示' : '在小程序隐藏'}
            {!isGameHidable && '(当前不展示)'}
          </Button>
        </div>,
        <div className="TournamentDetailModalFooterButtonGroup flex flex-middle">
          <Button
            className="TournamentDetailModalFooterButton flex__fluid-content"
            type="primary"
            loading={false}
            onClick={handleSaveGameButtonClick}
          >
            保存草稿
          </Button>
          <Button
            ghost
            className="TournamentDetailModalFooterButton flex__fluid-content"
            type="primary"
            onClick={handleCancelButtonClick}
          >
            取消
          </Button>
        </div>,
      ]}
      onOk={handleSaveGameButtonClick}
      onCancel={handleCancelButtonClick}
      afterClose={resetData}
    >
      {title && enrollmentId ? (
        <Form form={form} preserve={false}>
          <JudgesSelectionModal
            visible={showJudgeSelectionModal}
            demandIds={demandIds}
            onCancel={() => setShowJudgeSelectionModal(false)}
            onFinish={(demandIds) => {
              handleGameDetailDataChange({
                demandIds,
              });
              setShowJudgeSelectionModal(false);
              form.setFieldsValue({ demandIds });
            }}
          />
          <Form.Item
            className="TournamentDetailModalEnrollmentItem"
            label="选择报名"
            name={gameId ? [gameId, 'enrollmentId'] : 'enrollmentId'}
            rules={[{ required: true, message: `报名不能为空` }]}
            initialValue={enrollmentId}
          >
            <Select
              showSearch
              disabled
              loading={false}
              placeholder="请输入报名名称"
              value={enrollmentId}
              defaultActiveFirstOption={false}
              filterOption={false}
              onSearch={(value) => {
                clearTimeout(timer);

                timer = setTimeout(() => {
                  dispatch(loadEnrollmentList(value) as any);
                }, 400);
              }}
              onChange={(value) => {
                handleGameDetailDataChange({
                  enrollmentId: value,
                });
              }}
              notFoundContent={
                <div className="flex flex-column flex-middle flex-center">
                  <InboxOutlined />
                  <span>未搜到此报名</span>
                </div>
              }
              options={enrollmentSelectList}
            />
          </Form.Item>
          <Form.Item
            className="TournamentDetailModalTitleItem"
            label="赛事名称"
            name={gameId ? [gameId, 'title'] : 'title'}
            rules={[{ required: true, message: `赛事名称不能为空` }]}
            initialValue={title}
          >
            <Input
              type="text"
              value={title}
              placeholder="请输入赛事名称"
              onChange={(e) => {
                handleGameDetailDataChange({
                  title: e.target.value,
                });
              }}
            />
          </Form.Item>
          <Form.Item
            className="TournamentDetailModalTitleItem"
            label="地址"
            name={gameId ? [gameId, 'address'] : 'address'}
            rules={[{ required: true, message: `地址不能为空` }]}
            initialValue={address}
          >
            <Input
              type="text"
              value={title}
              placeholder="请输入赛事名称"
              onChange={(e) => {
                handleGameDetailDataChange({
                  address: e.target.value,
                });
              }}
            />
          </Form.Item>

          {enrollmentId && (
            <>
              <section>
                <h4>
                  基础信息
                  <Button
                    size="small"
                    type="primary"
                    disabled={!isGameDeletable}
                    onClick={handleSyncEnrollment}
                    style={{
                      margin: '0 8px',
                    }}
                  >
                    从报名同步选手
                    <SyncOutlined />
                  </Button>
                </h4>
                <ul className="TournamentDetailModalBasicInfo">
                  <li className="flex flex-middle">
                    <span>参赛人数： </span>
                    <Button
                      className="TournamentDetailModalBasicInfoLink"
                      type="link"
                      onClick={() => {
                        navigate(
                          `/association-activities/enrollments/${enrollmentId}/players?category=players`
                        );
                      }}
                    >
                      {enrolledCount}
                    </Button>
                  </li>
                  <li className="flex flex-middle">
                    <span>参赛团队： </span>
                    <Button
                      className="TournamentDetailModalBasicInfoLink"
                      type="link"
                      onClick={() => {
                        navigate(
                          `/association-activities/enrollments/${enrollmentId}/players?category=team`
                        );
                      }}
                    >
                      {multiPlayerTeamCount}
                    </Button>
                    {enrollmentPlayersNumberUnclassified &&
                    enrollmentPlayersNumberUnclassified > 0 ? (
                      <>
                        <span className="TournamentDetailModalBasicInfoLinkText">
                          ，还有{enrollmentPlayersNumberUnclassified}
                          人未分组！
                        </span>
                        <Button
                          type="primary"
                          onClick={() => {
                            window.open(
                              `${window.location.origin}${
                                window.location.pathname
                              }#${`/association-activities/enrollments/${enrollmentId}/players?category=team`}`,
                              '_blank'
                            );
                          }}
                        >
                          <span>前往分组</span>
                          <RightOutlined />
                        </Button>
                      </>
                    ) : null}
                  </li>
                </ul>
                <Form.Item
                  label="赛程设置"
                  name={gameId ? [gameId, 'eventSchedule'] : 'eventSchedule'}
                  rules={[{ required: true, message: `赛程设置不能为空` }]}
                  initialValue={eventSchedule || 'qualifyingAndKnockout'}
                >
                  <Radio.Group
                    value={eventSchedule}
                    onChange={(e) => {
                      handleGameDetailDataChange({
                        eventSchedule: e.target.value,
                      });
                    }}
                  >
                    <Radio
                      style={{ userSelect: 'none' }}
                      value="qualifyingAndKnockout"
                    >
                      传统联赛
                    </Radio>
                  </Radio.Group>
                  <Radio.Group
                    value={eventSchedule}
                    onChange={(e) => {
                      handleGameDetailDataChange({
                        eventSchedule: e.target.value,
                      });
                    }}
                  >
                    <Radio
                      style={{ userSelect: 'none' }}
                      value="onlyQualifying"
                    >
                      达级赛
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="分数录入方式"
                  name={
                    gameId
                      ? [gameId, 'scoreRecordingType']
                      : 'scoreRecordingType'
                  }
                  rules={[{ required: true, message: `分数录入方式不能为空` }]}
                  initialValue={scoreRecordingType}
                >
                  <Radio.Group
                    value={scoreRecordingType}
                    onChange={(e) => {
                      handleGameDetailDataChange({
                        scoreRecordingType: e.target.value,
                      });
                    }}
                  >
                    <Radio style={{ userSelect: 'none' }} value="crossEntry">
                      交叉录入
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  label="分数核验方式"
                  name={
                    gameId
                      ? [gameId, 'scoreVerificationMethod']
                      : 'scoreVerificationMethod'
                  }
                  rules={[{ required: true, message: `分数核验方式不能为空` }]}
                  initialValue={scoreVerificationMethod}
                >
                  <Radio.Group
                    onChange={(e) => {
                      handleGameDetailDataChange({
                        scoreVerificationMethod: e.target.value,
                      });
                    }}
                    value={scoreVerificationMethod}
                  >
                    <Radio
                      style={{ userSelect: 'none' }}
                      value="noVerification"
                    >
                      无需验证
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </section>

              <Form.Item
                className="TournamentDetailModalDemandedItem"
                label="设置裁判员"
                name="demandIds"
                rules={[
                  {
                    required: true,
                    message: '裁判员不能为空',
                  },
                ]}
                initialValue={demandIds}
              >
                <div className="CreateTournamentModalJudges">
                  {demandIds.map((d: string) => (
                    <Tag key={d}>
                      {employeeCheckBoxList.find((e) => e.value === d)?.label ||
                        d}
                    </Tag>
                  ))}
                  <Button
                    type="link"
                    size="small"
                    style={{ padding: 0 }}
                    onClick={() => setShowJudgeSelectionModal(true)}
                  >
                    点击编辑
                  </Button>
                </div>
              </Form.Item>

              <section className="flex flex-middle">
                <Form.Item
                  className="TournamentDetailModalTargetItem"
                  label="靶位排布"
                  rules={[
                    {
                      required: true,
                      message: '靶位排布不能为空',
                    },
                  ]}
                >
                  {isTargetsSettingEnabled ? (
                    isTargetsSet ? (
                      <div className="flex flex-middle">
                        <span className="flex__shrink-fixed">已设置</span>
                        <Button
                          block
                          type="link"
                          onClick={handleTargetSettingModalButtonClick}
                        >
                          编辑
                        </Button>
                      </div>
                    ) : (
                      <Button
                        block
                        onClick={handleTargetSettingModalButtonClick}
                      >
                        点击设置靶位排布
                      </Button>
                    )
                  ) : (
                    <Button
                      block
                      type="link"
                      onClick={handleTargetSettingModalButtonClick}
                    >
                      查看靶位设置
                    </Button>
                  )}
                </Form.Item>
              </section>
            </>
          )}
        </Form>
      ) : null}
    </Modal>
  );
};

TournamentDetailModal.displayName = 'TournamentDetailModal';

export default TournamentDetailModal;
