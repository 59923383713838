import { message, Modal } from 'antd';

const feedbackMessage = (
  type: string,
  options: {
    content: JSX.Element | string;
    title?: string;
    okText?: string;
    centered?: boolean;
    zIndex: number;
    handleConfirm?: () => void;
  }
) => {
  const { handleConfirm, ...rest } = options;
  const currentOptions = {
    ...rest,
    onOk: handleConfirm,
  };

  switch (type) {
    case 'success':
      Modal.success(currentOptions);
      break;
    case 'error':
      Modal.error(currentOptions);
      break;
    case 'warn':
      Modal.warn(currentOptions);
      break;
    default:
      return;
  }
};

export const successMessageToast = (
  title: string,
) => {
  message.success(title);
}

export const successMessage = (
  content: JSX.Element | string,
  options?: {
    title?: string;
    okText?: string;
    centered?: boolean;
    zIndex?: number;
    handleConfirm?: () => void;
  }
) => {
  const {
    title,
    okText,
    centered = true,
    zIndex = 1000,
    handleConfirm,
  } = options || {};

  feedbackMessage('success', {
    title: title,
    content: content,
    okText: okText || '确定',
    centered,
    zIndex,
    handleConfirm,
  });
};

export const errorMessage = (
  content: JSX.Element | string,
  options?: {
    title?: string;
    okText?: string;
    centered?: boolean;
    zIndex?: number;
    handleContinue?: () => void;
  }
) => {
  const {
    title,
    okText,
    centered = true,
    zIndex = 1000,
    handleContinue,
  } = options || {};

  feedbackMessage('error', {
    title: title,
    content: content,
    okText: okText || '知道了',
    centered,
    zIndex,
    handleConfirm: handleContinue,
  });
};

export const warnMessage = (
  content: JSX.Element | string,
  options?: {
    title?: string;
    okText?: string;
    centered?: boolean;
    zIndex?: number;
    handleConfirm?: () => void;
  }
) => {
  const {
    title,
    okText,
    centered = true,
    zIndex = 1000,
    handleConfirm,
  } = options || {};

  feedbackMessage('warn', {
    title: title,
    content: content,
    okText: okText || '知道了',
    centered,
    zIndex,
    handleConfirm,
  });
};
