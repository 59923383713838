/* eslint-disable react-hooks/rules-of-hooks */
import _isNill from 'lodash/isNil';
import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Modal, Tag, Typography } from 'antd';
import {
  ExclamationCircleOutlined,
  PlusCircleOutlined,
  DownOutlined,
  UpOutlined,
} from '@ant-design/icons';
import { useMount } from 'react-use';
import { useNavigate } from 'react-router-dom';
import { AppDispatch } from '../../../../../../common/utils/types';
import { TOURNAMENT_STATUS } from './utils/constants';
import { getIsUserInitialized } from '../../../../../../redux/app-v2/user/selectors';
import {
  getTournamentListPageNumber,
  getTournamentListPageSize,
  getTournamentList,
  getTournamentListTotal,
} from './redux/selectors';
import {
  exportTargetsSettingExcel,
  exportCurrentStageScoreSheet,
  goToNextSegment,
  loadTournamentList,
  updateGameStatus,
  updateSegmentStatus,
} from './redux/thunks';
import { actions as tournamentListActions } from './redux';
import TableResponsiveFrame from '../../../../../../common/components/tables/TableResponsiveFrame';
import { ContentTableColumnPropsType } from '../../../../../../common/components/tables/ContentTable/types';
import ContentDetail from '../../../../../../common/components/ContentDetail';
import CreateTournamentModal from './components/CreateTournamentModal';
import TournamentDetailModal from './components/TournamentDetailModal';
import TargetsSettingModal from './components/TargetsSettingModal';
import './TournamentList.less';
import { errorMessage } from '../../../../../../common/utils/feedback';

interface JudgesViewProps {
  judges: any[];
}
const JudgesView: FC<JudgesViewProps> = ({ judges }) => {
  const navigate = useNavigate();

  const [open, setOpen] = React.useState(false);
  const items = open
    ? judges
    : judges.slice(0, Math.min(4, Math.max(judges.length, 0)));
  const itemsComponent = items.map((judge, index) => {
    return (
      <Button
        className="TournamentListJudgeButton"
        type="primary"
        ghost
        size="small"
        key={judge.id}
        onClick={(e) => {
          e.stopPropagation();
          navigate(`/employees/${judge.id}`);
        }}
      >
        {judge.name}
      </Button>
    );
  });
  return (
    <div>
      <div>{itemsComponent}</div>
      {judges.length > 4 && (
        <Button
          className="TournamentListJudgeButtonMore"
          key={'tournamentListJudgeButtonMore'}
          type="primary"
          size="small"
          icon={open ? <UpOutlined /> : <DownOutlined />}
          onClick={(e) => {
            e.stopPropagation();
            setOpen(!open);
          }}
        >
          {open ? '收起' : `更多`}
        </Button>
      )}
    </div>
  );
};

const TournamentList = () => {
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const isUserInitialized = useSelector(getIsUserInitialized);
  const tournamentList = useSelector(getTournamentList);
  const pageNumber = useSelector(getTournamentListPageNumber);
  const pageSize = useSelector(getTournamentListPageSize);
  const dataTotal = useSelector(getTournamentListTotal);

  const columns: ContentTableColumnPropsType[] = [
    {
      title: '比赛标题',
      width: 200,
      dataIndex: 'title',
      key: 'title',
      align: 'center',
      mobileAlign: 'headerLeft',
      render: (title, record) => {
        const isHidden = record.isHidden || false;
        return (
          <>
            {title}
            <br />
            {isHidden && <span style={{ color: '#8c8c8c' }}>(已隐藏)</span>}
          </>
        );
      },
    },
    {
      title: '参与人数',
      width: 120,
      dataIndex: 'enrolledCount',
      key: 'enrolledCount',
      align: 'center',
    },
    {
      title: '裁判',
      dataIndex: 'judges',
      key: 'judges',
      align: 'center',
      render: (judges: any[]) => {
        return <JudgesView judges={judges} />;
      },
    },
    {
      title: '比赛创建时间',
      dataIndex: 'createdTime',
      key: 'createdTime',
      align: 'center',
    },
    {
      title: '状态',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render(status, record) {
        const { currentSegment } = record || {};
        const { displayName, status: currentSegmentStatus } =
          currentSegment || {};
        let currentSegmentStatusText = '';

        switch (currentSegmentStatus) {
          case 'preparation':
            currentSegmentStatusText = '-预备中';
            break;
          case 'started':
            currentSegmentStatusText = '-进行中';
            break;
          case 'ended':
            currentSegmentStatusText = `-已结束`;
            break;
          default:
            currentSegmentStatusText = '';
        }

        switch (status) {
          case TOURNAMENT_STATUS.CREATED:
            return '待设置靶位';
          case TOURNAMENT_STATUS.TARGET_SET:
            return '靶位已设置';
          case TOURNAMENT_STATUS.STARTED:
            return displayName ? (
              <>
                {displayName || ''}
                {currentSegmentStatusText}
              </>
            ) : (
              '进行中'
            );
          case TOURNAMENT_STATUS.ENDED:
            return '已结束';
          default:
            return '-';
        }
      },
    },
    {
      title: '操作',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (status: string, record) => {
        const {
          id,
          title,
          currentSegment,
          nextSegment,
          nextCompetitionSegment,
        }: any = record;
        const {
          id: currentSegmentId,
          status: currentSegmentStatus,
          segmentType,
          displayName: currentSegmentDisplayName,
        } = currentSegment || {};

        const exportTargetsSettingExcelButton = (
          <Button
            type="text"
            className="TournamentListControlButton"
            onClick={(e) => {
              e.stopPropagation();
              dispatch(exportTargetsSettingExcel(id) as any).then(
                (response: any) => {
                  const fileName = `${title} - 靶位信息`;

                  if (!_isNill(response.payload)) {
                    const blob = new Blob([response.payload]);
                    const reader = new FileReader();

                    reader.readAsText(blob);
                    reader.onload = (event: any) => {
                      if (event.target?.result.indexOf('"statusCode"') >= 0) {
                        errorMessage(`下载${fileName}失败`);
                      } else {
                        if ('download' in document.createElement('a')) {
                          const link = document.createElement('a');

                          link.download = `${fileName}.xlsx`;
                          link.style.display = 'none';
                          link.href = URL.createObjectURL(blob);
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                        } else if ((navigator as any).msSaveBlob) {
                          (navigator as any).msSaveBlob(
                            blob,
                            `${fileName}.xlsx`
                          );
                        }
                      }
                    };
                  }
                }
              );
            }}
          >
            <Tag color="blue">导出靶位图</Tag>
          </Button>
        );
        const exportCurrentStageScoreSheetButton = (
          <Button
            type="text"
            className="TournamentListControlButton"
            onClick={(e) => {
              e.stopPropagation();

              dispatch(
                exportCurrentStageScoreSheet({
                  gameId: id,
                  segmentId: nextCompetitionSegment?.id || currentSegmentId,
                }) as any
              ).then((response: any) => {
                // console.log(response);
                const fileName = `${title}${currentSegmentDisplayName}记分`;

                if (!_isNill(response.payload)) {
                  const blob = new Blob([response.payload]);
                  const reader = new FileReader();

                  reader.readAsText(blob);
                  reader.onload = (event: any) => {
                    if (event.target?.result.indexOf('"statusCode"') >= 0) {
                      errorMessage(`下载${fileName}失败`);
                    } else {
                      if ('download' in document.createElement('a')) {
                        const link = document.createElement('a');

                        link.download = `${fileName}.zip`;
                        link.style.display = 'none';
                        link.href = URL.createObjectURL(blob);
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      } else if ((navigator as any).msSaveBlob) {
                        (navigator as any).msSaveBlob(blob, `${fileName}.zip`);
                      }
                    }
                  };
                }
              });
            }}
          >
            <Tag color="blue">{`导出${
              status === TOURNAMENT_STATUS.TARGET_SET
                ? '排位赛阶段'
                : segmentType === 'competition'
                ? '当前阶段'
                : '下一比赛阶段'
            }记分纸`}</Tag>
          </Button>
        );

        switch (status) {
          case TOURNAMENT_STATUS.CREATED:
            return (
              <>
                <Button
                  type="text"
                  className="TournamentListControlButton"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await dispatch(
                      tournamentListActions.loadGameDetailRequestSelectedGameIdUpdated(
                        id
                      )
                    );
                    dispatch(
                      tournamentListActions.loadGameDetailRequestTargetsSettingModalShown()
                    );
                  }}
                >
                  <Tag color="blue">设置靶位</Tag>
                </Button>
                <Button
                  type="text"
                  className="TournamentListControlButton"
                  onClick={async (e) => {
                    e.stopPropagation();

                    Modal.confirm({
                      centered: true,
                      title: `完成靶位设置之后，靶位将不再支持更改`,
                      icon: <ExclamationCircleOutlined />,
                      okText: '确认',
                      cancelText: '取消',
                      onOk: async () => {
                        await await dispatch(
                          updateGameStatus({
                            gameId: id,
                            status: TOURNAMENT_STATUS.TARGET_SET,
                          })
                        );
                      },
                    });
                  }}
                >
                  <Tag color="blue">完成靶位设置</Tag>
                </Button>
              </>
            );
          case TOURNAMENT_STATUS.TARGET_SET:
            return (
              <>
                <Button
                  type="text"
                  className="TournamentListControlButton"
                  onClick={async (e) => {
                    e.stopPropagation();
                    await await dispatch(
                      updateGameStatus({
                        gameId: id,
                        status: TOURNAMENT_STATUS.STARTED,
                      })
                    );
                  }}
                >
                  <Tag color="success">开始比赛</Tag>
                </Button>
                {exportTargetsSettingExcelButton}
                {exportCurrentStageScoreSheetButton}
              </>
            );
          case TOURNAMENT_STATUS.STARTED:
            return (
              <>
                {exportTargetsSettingExcelButton}
                {(segmentType === 'competition' || nextCompetitionSegment) &&
                  exportCurrentStageScoreSheetButton}
                {currentSegmentStatus === 'preparation' ? (
                  <Button
                    type="text"
                    className="TournamentListControlButton"
                    onClick={async (e) => {
                      e.stopPropagation();

                      Modal.confirm({
                        centered: true,
                        title: `确认开始当前阶段按钮？当前阶段为：${currentSegment?.displayName}`,
                        icon: <ExclamationCircleOutlined />,
                        okText: '确认',
                        cancelText: '取消',
                        onOk: async () => {
                          await dispatch(
                            updateSegmentStatus({
                              gameId: id,
                              segmentId: currentSegmentId,
                              status: 'started',
                            })
                          );
                        },
                      });
                    }}
                  >
                    <Tag color="success">开始当前轮</Tag>
                  </Button>
                ) : null}
                {currentSegmentStatus === 'started' ? (
                  <Button
                    type="text"
                    className="TournamentListControlButton"
                    onClick={async (e) => {
                      e.stopPropagation();

                      Modal.confirm({
                        centered: true,
                        title: nextSegment
                          ? `确认进入下一阶段？下一阶段为：${nextSegment?.displayName}`
                          : '确认结束比赛？',
                        content: `${
                          segmentType === 'competition' &&
                          currentSegmentStatus === 'started'
                            ? `请检查分数是否完全录入，未完成分数录入选手的下一轮排名将受到影响`
                            : ''
                        }`,
                        icon: <ExclamationCircleOutlined />,
                        okText: '确认',
                        cancelText: '取消',
                        onOk: async () => {
                          await dispatch(goToNextSegment(id));
                        },
                      });
                    }}
                  >
                    <Tag color="success">进入下一阶段</Tag>
                  </Button>
                ) : null}
              </>
            );
          default:
            return undefined;
        }
      },
    },
  ];

  useMount(() => {
    dispatch(loadTournamentList() as any);
  });

  useEffect(() => {
    if (isUserInitialized) {
      dispatch(loadTournamentList() as any);
    }
  }, [pageNumber, pageSize, isUserInitialized]);

  return (
    <>
      <ContentDetail
        title="赛事活动联赛"
        headerRight={
          <div className="NewTournamentButtonContainer">
            <Button
              className="NewTournamentButton"
              onClick={() => {
                dispatch(
                  tournamentListActions.createGameRequestCreateModalShown() as any
                );
              }}
            >
              <PlusCircleOutlined className="NewTournamentButtonOutlinedIcon" />
              <span className="NewTournamentButtonText">创建比赛</span>
            </Button>
          </div>
        }
      >
        <TableResponsiveFrame
          columns={columns}
          pageNumber={pageNumber + 1}
          pageSize={pageSize}
          data={tournamentList}
          dataItemsTotal={dataTotal}
          onRowClick={async (record: any) => {
            const { id } = record;
            await dispatch(
              tournamentListActions.loadGameDetailRequestSelectedGameIdUpdated(
                id
              )
            );
            dispatch(
              tournamentListActions.loadGameDetailRequestDetailModalShown()
            );
            return true;
          }}
          onChangePageSize={async (_: number, pageSize: number) => {
            await dispatch(tournamentListActions.updatePageSize(pageSize));
          }}
          onChangePageNumber={async (pageNumber: number) => {
            await dispatch(
              tournamentListActions.updatePageNumber(pageNumber - 1)
            );
          }}
        />
      </ContentDetail>
      <TournamentDetailModal />
      <CreateTournamentModal />
      <TargetsSettingModal />
    </>
  );
};

export default TournamentList;
