import _get from 'lodash/get';
import _uniqWith from 'lodash/uniqWith';
import _groupBy from 'lodash/groupBy';
import _map from 'lodash/map';
import _differenceWith from 'lodash/differenceWith';
import _isEqual from 'lodash/isEqual';
import _flattenDeep from 'lodash/flattenDeep';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '../../../../../../../common/utils/types';
import { API_REQUEST_STATUS } from '../../../../../../../service/constants';
import { generateColor, generateDisabledColor } from '../utils';
import {
  TOURNAMENT_NOT_START_STATUS,
  TOURNAMENT_STATUS,
} from '../utils/constants';
import { getEmployeesMapping } from '../../../../../../../redux/entities/employees/selectors';
import _omit from 'lodash/omit';

export const getLoadTournamentListData = (state: RootState) =>
  state.association.tournaments.loadTournamentListRequest.data || {};

export const getLoadTournamentListStatus = (state: RootState) =>
  state.association.tournaments.loadTournamentListRequest.status;

export const getLoadTournamentListError = (state: RootState) =>
  state.association.tournaments.loadTournamentListRequest.error;

export const getTournamentListDataList = createSelector(
  getLoadTournamentListData,
  (loadTournamentListData) => loadTournamentListData.list || []
);

export const getTournamentListSearchInputText = createSelector(
  getLoadTournamentListData,
  (loadTournamentListData) => {
    return loadTournamentListData.searchInputText;
  }
);

export const getTournamentListPageNumber = createSelector(
  getLoadTournamentListData,
  (loadTournamentListData) => {
    return loadTournamentListData.pageNumber;
  }
);

export const getTournamentListPageSize = createSelector(
  getLoadTournamentListData,
  (loadTournamentListData) => {
    return loadTournamentListData.pageSize;
  }
);

export const getTournamentListTotal = createSelector(
  getLoadTournamentListData,
  (loadTournamentListData) => {
    return loadTournamentListData.total;
  }
);

export const getEnrollmentListData = (state: RootState) =>
  state.association.tournaments.loadEnrollmentListRequest.data || {};

export const getEnrollmentListStatus = (state: RootState) =>
  state.association.tournaments.loadEnrollmentListRequest.status;

export const getEnrollmentListError = (state: RootState) =>
  state.association.tournaments.loadEnrollmentListRequest.error;

export const getEnrollmentDataList = createSelector(
  getEnrollmentListData,
  (enrollmentListData) => enrollmentListData?.list || []
);

export const getEnrollmentListSearchInputText = createSelector(
  getEnrollmentListData,
  (loadEnrollmentListData) => {
    return loadEnrollmentListData.searchInputText;
  }
);

export const getEnrollmentListPageNumber = createSelector(
  getEnrollmentListData,
  (loadEnrollmentListData) => {
    return loadEnrollmentListData.pageNumber;
  }
);

export const getEnrollmentListPageSize = createSelector(
  getEnrollmentListData,
  (loadEnrollmentListData) => {
    return loadEnrollmentListData.pageSize;
  }
);

export const getLoadEnrollmentPlayersUnclassifiedData = (state: RootState) =>
  state.association.tournaments.loadEnrollmentPlayersUnclassifiedRequest.data ||
  [];

export const getEmployeeListData = (state: RootState) =>
  state.association.tournaments.loadEmployeeListRequest.data || [];

export const getEmployeeListStatus = (state: RootState) =>
  state.association.tournaments.loadEmployeeListRequest.status;

export const getEmployeeListError = (state: RootState) =>
  state.association.tournaments.loadEmployeeListRequest.error;

export const getEmployeeIds = createSelector(
  getEmployeeListData,
  (employeeListData) => {
    return employeeListData.ids;
  }
);

export const getEmployeeListPageNumber = createSelector(
  getEmployeeListData,
  (loadEmployeeListData) => {
    return loadEmployeeListData.pageNumber;
  }
);

export const getEmployeeListPageSize = createSelector(
  getEmployeeListData,
  (loadEmployeeListData) => {
    return loadEmployeeListData.pageSize;
  }
);

export const getGameLegendData = (state: RootState) =>
  state.association.tournaments.loadGameLegendRequest.data || {};

export const getGameLegendStatus = (state: RootState) =>
  state.association.tournaments.loadGameLegendRequest.status;

export const getGameLegendError = (state: RootState) =>
  state.association.tournaments.loadGameLegendRequest.error;

export const getGameLegendTournaments = createSelector(
  getGameLegendData,
  (gameLegendData) => _get(gameLegendData, 'tournaments', [])
);

export const getGameDetailData = (state: RootState) =>
  state.association.tournaments.loadGameDetailRequest.data || {};

export const getGameDetailStatus = (state: RootState) =>
  state.association.tournaments.loadGameDetailRequest.status;

export const getGameDetailError = (state: RootState) =>
  state.association.tournaments.loadGameDetailRequest.error;

export const getGameDetailLocalData = (state: RootState) =>
  state.association.tournaments.loadGameDetailRequest.localData;

export const getGameDetailTitle = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.title
);

export const getGameDetailAddress = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.address
);

export const getGameDetailEnrollmentId = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.enrollmentId
);

export const getGameDetailEnrolledCount = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.enrolledCount
);

export const getGameDetailMultiPlayerTeamCount = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.multiPlayerTeamCount
);

export const getGameDetailIndividualEnrolledCount = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.individualEnrolledCount
);

export const getGameDetailScoreRecordingType = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.scoreRecordingType
);

export const getGameDetailEventScheduleType = createSelector(
  getGameDetailData,
  (gameDetailData) => (gameDetailData.eventSchedule || 'qualifyingAndKnockout') as 'qualifyingAndKnockout' | 'onlyQualifying'
);

export const getGameDetailScoreVerificationMethod = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.scoreVerificationMethod
);

export const getGameDetailDemandIds = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.demandIds
);

export const getGameDetailIsHidden = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.isHidden || false
);

export const getGameDetailGameStatus = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.status
);

export const getGameDetailTargetNums = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.targetNums || []
);

export const getGameDetailSegments = createSelector(
  getGameDetailData,
  (gameDetailData) => gameDetailData.segments?.map((segment: any) => {
    return {
      ...segment,
      arranges: segment.targets?.map((target: any) => Boolean(!target.archeryTournamentRoundSettings)),
    }
  }) || []
);

export const getGameDetailSelectedGameId = createSelector(
  getGameDetailLocalData,
  getGameDetailData,
  (gameDetailLocalData) => gameDetailLocalData.selectedGameId
);

export const getGameDetailShowDetailModal = createSelector(
  getGameDetailLocalData,
  (gameDetailLocalData) => gameDetailLocalData.showDetailModal
);

export const getGameDetailShowTargetsSettingModal = createSelector(
  getGameDetailLocalData,
  (gameDetailLocalData) => gameDetailLocalData.showTargetsSettingModal
);

export const getGameDetailSelectedTargets = createSelector(
  getGameDetailLocalData,
  (gameDetailLocalData) => gameDetailLocalData.selectedTargets || {}
);

export const getGameDetailShowSelectedTargetsSettingPopover = createSelector(
  getGameDetailLocalData,
  (gameDetailLocalData) => gameDetailLocalData.showSelectedTargetSettingPopover
);

export const getGameDetailSelectedSetTargetsInfo = createSelector(
  getGameDetailLocalData,
  (gameDetailLocalData) => gameDetailLocalData.selectedSetTargetsInfo
);

export const getCreateGameRequestData = (state: RootState) =>
  state.association.tournaments.createGameRequest.data || {};

export const getCreateGameRequestLocalData = (state: RootState) =>
  state.association.tournaments.createGameRequest.localData;

export const getCreateGameRequestStatus = (state: RootState) =>
  state.association.tournaments.createGameRequest.status;

export const getCreateGameRequestError = (state: RootState) =>
  state.association.tournaments.createGameRequest.error;

export const getCreateGameRequestTargetNums = createSelector(
  getCreateGameRequestData,
  (createGameRequestData) => createGameRequestData.targetNums
);

export const getCreateGameRequestSegments = createSelector(
  getCreateGameRequestData,
  (createGameRequestData) => createGameRequestData.segments.map((segment) => {
    return {
      ...segment,
      arranges: segment.targets?.map((target) => Boolean(!target.archeryTournamentRoundSettings)),
    }
  })
);

export const getCreateGameRequestSelectedEnrollmentId = createSelector(
  getCreateGameRequestLocalData,
  (createGameRequestLocalData) =>
    createGameRequestLocalData.selectedEnrollmentId
);

export const getCreateGameRequestShowCreateModal = createSelector(
  getCreateGameRequestLocalData,
  (createGameRequestLocalData) => createGameRequestLocalData.showCreateModal
);

export const getCreateGameRequestShowTargetsSettingModal = createSelector(
  getCreateGameRequestLocalData,
  (createGameRequestLocalData) =>
    createGameRequestLocalData.showTargetsSettingModal
);

export const getCreateGameRequestSelectedTargets = createSelector(
  getCreateGameRequestLocalData,
  (createGameRequestLocalData) =>
    createGameRequestLocalData.selectedTargets || {}
);

export const getCreateGameRequestShowSelectedTargetsSettingPopover =
  createSelector(
    getCreateGameRequestLocalData,
    (createGameRequestLocalData) =>
      createGameRequestLocalData.showSelectedTargetSettingPopover
  );

export const getCreateGameRequestSelectedSetTargetsInfo = createSelector(
  getCreateGameRequestLocalData,
  (createGameRequestLocalData) =>
    createGameRequestLocalData.selectedSetTargetsInfo
);

export const getQueryUpdateGameDetailRequestStatus = (state: RootState) =>
  state.association.tournaments.queryUpdateGameDetailRequest.status;

export const getQueryUpdateGameDetailRequestError = (state: RootState) =>
  state.association.tournaments.queryUpdateGameDetailRequest.error;

// 衍生数据
export const getTournamentList = createSelector(
  getTournamentListDataList,
  (tournamentListDataList) =>
    tournamentListDataList.map(
      (rowDataSource: {
        id: string;
        demandIds: string[];
        scoreRecordingType: string;
        scoreVerificationMethod: string;
        title: string | undefined;
        enrollmentId: string | undefined;
        enrolledCount: number | undefined;
        judges:
        | Array<{
          id: string | undefined;
          name: string;
        }>
        | undefined;
        createdTime: string | undefined;
        status: string | undefined;
      }) => {
        const { createdTime } = rowDataSource;

        return {
          ...rowDataSource,
          createdTime: createdTime?.substring(0, 10),
        };
      }
    )
);


export const getDisplayEnrollmentSelectList = createSelector(
  getEnrollmentDataList,
  (enrollmentDataList: any[]) => {
    return enrollmentDataList?.map((item: { id: string; title: string }) => {
      const { id, title } = item;
      return {
        value: id,
        label: title,
      };
    })
  }
);

export const getEnrollmentSelectList = createSelector(
  getEnrollmentDataList,
  (enrollmentDataList: any[]) => {
    return enrollmentDataList?.map((item: { id: string; title: string }) => {
      const { id, title } = item;
      return {
        value: id,
        label: title,
      };
    })
  }
);

export const getHasSelectedEnrollmentId = createSelector(
  getGameDetailEnrollmentId,
  getEnrollmentDataList,
  (gameDetailEnrollmentId, enrollmentDataList) => {
    const selectedIndex = enrollmentDataList?.findIndex(
      (item: any) => item.id === gameDetailEnrollmentId
    );

    return selectedIndex > -1;
  }
);

export const getIsLoadEnrollmentListPending = createSelector(
  getEnrollmentListStatus,
  (status) => status === API_REQUEST_STATUS.PENDING
);

export const getEmployeeCheckBoxList = createSelector(
  getEmployeesMapping,
  getEmployeeIds,
  (employeesMapping, ids) => {
    const filteredIds = ids.filter((id: string) => employeesMapping[id]);

    return filteredIds.map((id: string) => {
      return {
        value: employeesMapping[id].id,
        label: employeesMapping[id].name,
        phone: employeesMapping[id].phone,
      };
    });
  }
);

export const getIsGameDeletable = createSelector(
  getGameDetailGameStatus,
  (gameDetailGameStatus) =>
    TOURNAMENT_NOT_START_STATUS.includes(gameDetailGameStatus)
);

export const getIsGameHidable = createSelector(
  getGameDetailGameStatus,
  (gameDetailGameStatus) =>
    !TOURNAMENT_NOT_START_STATUS.includes(gameDetailGameStatus)
);

export const getIsGameJudgesEditable = createSelector(
  getGameDetailGameStatus,
  (gameDetailGameStatus) =>
    TOURNAMENT_NOT_START_STATUS.includes(gameDetailGameStatus)
);

export const getIsCreateTargetsSettingModal = createSelector(
  getCreateGameRequestShowTargetsSettingModal,
  (createGameRequestShowTargetsSettingModal) =>
    !!createGameRequestShowTargetsSettingModal
);

export const getIsDetailTargetsSettingModal = createSelector(
  getGameDetailShowTargetsSettingModal,
  (gameDetailShowTargetsSettingModal) => !!gameDetailShowTargetsSettingModal
);

export const getIsTargetsSettingModalShow = createSelector(
  getGameDetailShowTargetsSettingModal,
  getCreateGameRequestShowTargetsSettingModal,
  (
    gameDetailShowTargetsSettingModal,
    createGameRequestShowTargetsSettingModal
  ) => {
    return (
      gameDetailShowTargetsSettingModal ||
      createGameRequestShowTargetsSettingModal
    );
  }
);

export const getSetTargetLegendData = createSelector(
  getIsCreateTargetsSettingModal,
  getGameDetailSegments,
  getCreateGameRequestSegments,
  (
    isCreateTargetsSettingModal,
    gameDetailSegments,
    createGameRequestSegments
  ) => {
    const segments = isCreateTargetsSettingModal
      ? createGameRequestSegments
      : gameDetailSegments;
    const targetsList = segments?.map((segment: any) => {
      const { targets } = segment || {};
      const selectedTargets: any[] = targets?.filter((t: any) => t.archeryTournamentRoundSettings).map((target: any) => {
        const { archeryTournamentRoundSettings } = target || {};
        const {
          categoryName,
          optionName,
          roundNumber,
          isQualifying,
          competitionHalf,
        } = archeryTournamentRoundSettings || {};

        return {
          categoryName,
          optionName,
          roundNumber,
          isQualifying,
          competitionHalf,
          targetNumChildScope: target.targetNumChildScope,
        };
      });

      const groupedTargets = _groupBy(selectedTargets, (i) => JSON.stringify(i));
      const targetsList = _map(groupedTargets, (value, key) => {
        const target = JSON.parse(key);
        return {
          ...target,
        }
      });
      return targetsList;
    });

    return _uniqWith(_flattenDeep(targetsList), _isEqual);
  }
);

export const getTargetLegends = createSelector(
  getSetTargetLegendData,
  getGameLegendTournaments,
  (setTargetLegendData, gameLegendTournaments) =>
    gameLegendTournaments.map((tournament: any, index: number) => {
      const {
        categoryName,
        optionName,
        rounds,
        enrolledCount,
        teamPlayerCount,
      } = tournament || {};
      const color = generateColor(index);
      const newRounds = rounds.map((round: any) => {
        const { roundNumber, isQualifying, competitionHalf, targetNumChildScope } = round || {};
        const compareData = {
          categoryName,
          optionName,
          roundNumber,
          isQualifying,
          competitionHalf
        };

        const isSelectedTarget: any = setTargetLegendData.find((item: any) => {
          return _isEqual({
            categoryName: item.categoryName,
            optionName: item.optionName,
            roundNumber: item.roundNumber,
            isQualifying: item.isQualifying,
            competitionHalf: item.competitionHalf,
          }, compareData);
        });

        return {
          ...round,
          isSelected: Boolean(round && isSelectedTarget),
          targetNumChildScope: isSelectedTarget?.targetNumChildScope || targetNumChildScope || [],
        };
      });

      return {
        ...tournament,
        rounds: newRounds,
        color: color,
        disabledColor: generateDisabledColor(index),
        enrolledCount: enrolledCount || 0,
        teamPlayerCount: teamPlayerCount || 0,
      };
    })
);

export const getTargetNums = createSelector(
  getIsCreateTargetsSettingModal,
  getGameDetailTargetNums,
  getCreateGameRequestTargetNums,
  (
    isCreateTargetsSettingModal,
    gameDetailTargetNums,
    createGameRequestTargetNums
  ) =>
    isCreateTargetsSettingModal
      ? createGameRequestTargetNums
      : gameDetailTargetNums
);

export const getTargetNumChildScope = createSelector(
  getIsCreateTargetsSettingModal,
  getGameDetailSegments,
  getCreateGameRequestSegments,
  (
    isCreateTargetsSettingModal,
    gameDetailSegments,
    createGameRequestSegments
  ) => {
    const segments = isCreateTargetsSettingModal
      ? createGameRequestSegments
      : gameDetailSegments;
    const targetNumMergedChildScope: any = [];

    segments?.forEach((segment: any) => {
      const { targets } = segment || {};

      (targets || []).forEach((target: any) => {
        const { targetNumChildScope } = target || {};

        (targetNumChildScope || []).forEach((item: any) => {
          if (!targetNumMergedChildScope.includes(item)) {
            targetNumMergedChildScope.push(item);
          }
        });
      });
    });

    return targetNumMergedChildScope;
  }
);

export const getTargetsSetLengthForTournament = createSelector(
  getTargetNums,
  getTargetNumChildScope,
  (targetNums, targetNumChildScope) =>
    (targetNums?.length || 0) * targetNumChildScope.length
);

export const getEnrollmentPlayersNumberUnclassified = createSelector(
  getLoadEnrollmentPlayersUnclassifiedData,
  (loadEnrollmentPlayersUnclassifiedData) => {
    let playersNumber = 0;

    loadEnrollmentPlayersUnclassifiedData.forEach((item: any) => {
      const { formPlayers }: any = item || {};

      playersNumber += formPlayers.length;
    });

    return playersNumber;
  }
);

export const getIsTargetsSettingEnabled = createSelector(
  getGameDetailGameStatus,
  (gameDetailGameStatus) => TOURNAMENT_STATUS.CREATED === gameDetailGameStatus
);

export const getIsGameNotStarted = createSelector(
  getGameDetailGameStatus,
  (gameDetailGameStatus) => {
    return TOURNAMENT_NOT_START_STATUS.includes(gameDetailGameStatus);
  }
);
