import React, { useEffect } from 'react';
import {
  Popconfirm,
  Button,
  InputNumber,
  Row,
  Col,
  Space,
  ConfigProvider,
} from 'antd';
import './index.less';

import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getCreateGameRequestSegments,
  getCreateGameRequestTargetNums,
  getIsCreateTargetsSettingModal,
  getGameDetailSegments,
  getGameDetailTargetNums,
  getIsTargetsSettingEnabled,
} from '../../../redux/selectors';
import { Segment, actions as gameRequestActions } from '../../../redux';
import SegmentSelectionPopover from './SegmentSelectionPopover';
import SegmentCell from './SegmentNewCell';

const TargetSettings = () => {
  const dispatch = useDispatch();
  const isCreateTargetsSettingModal = useSelector(
    getIsCreateTargetsSettingModal
  );

  const createTargetNums = useSelector(getCreateGameRequestTargetNums);
  const detailTargetNums = useSelector(getGameDetailTargetNums);
  const targetNums = isCreateTargetsSettingModal
    ? createTargetNums
    : detailTargetNums;
  const createSegments = useSelector(getCreateGameRequestSegments);
  const detailSegments = useSelector(getGameDetailSegments);
  const isTargetsSettingEnabled = useSelector(getIsTargetsSettingEnabled);
  const [addRoundPopUpVisible, setAddRoundPopUpVisible] =
    React.useState<boolean>(false);
  const segments = isCreateTargetsSettingModal
    ? createSegments
    : detailSegments;
  const lastSegmentTime =
    !segments || segments.length === 0
      ? null
      : segments[segments.length - 1]?.endTime;

  const onClickAddTargetNums = () => {
    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestTargetNumsUpdated(
          targetNums.concat(targetNums.length + 1 + '')
        )
      );
    } else {
      dispatch(
        gameRequestActions.detailGameTargetNumsUpdated(
          targetNums.concat(targetNums.length + 1 + '')
        )
      );
    }
  };

  const onDeleteSegment = (segment: Segment) => {
    const deletedSegments = segments.filter((item: any) => item !== segment);

    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestSegmentsUpdated(deletedSegments)
      );
    } else {
      dispatch(gameRequestActions.detailGameSegmentsUpdated(deletedSegments));
    }
  };

  const onInsertSegment = (segment: Segment, segmentIndex: number) => {
    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestSegmentInsert({
          segment,
          segmentIndex,
        })
      );
    } else {
      dispatch(
        gameRequestActions.detailGameSegmentInsert({ segment, segmentIndex })
      );
    }
  };

  const onUpdateSegment = (segment: Segment, segmentIndex: number) => {
    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestSegmentUpdated({
          segment,
          segmentIndex,
        })
      );
    } else {
      dispatch(
        gameRequestActions.detailGameSegmentUpdated({ segment, segmentIndex })
      );
    }
  };

  const onAddSegment = (segment: Segment) => {
    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestSegmentsUpdated(
          segments.concat(segment)
        )
      );
    } else {
      dispatch(
        gameRequestActions.detailGameSegmentsUpdated(segments.concat(segment))
      );
    }
  };

  const onSetTargetNums = (targetNums: string[]) => {
    if (isCreateTargetsSettingModal) {
      dispatch(
        gameRequestActions.createGameRequestTargetNumsUpdated(targetNums)
      );
    } else {
      dispatch(gameRequestActions.detailGameTargetNumsUpdated(targetNums));
    }
  };

  const generateTargetNums = (length: number): string[] => {
    const targetNums = [];
    for (let i = 1; i <= length; i++) {
      targetNums.push(i + '');
    }
    return targetNums;
  };

  useEffect(() => {
    const tableComponent = document.getElementById('TargetSetting');
    tableComponent?.scrollTo(0, tableComponent.scrollHeight);
  }, [segments.length]);

  return (
    <>
      <ConfigProvider>
        <div id="TargetSetting" className="TargetSettingsContainer">
          <Row
            gutter={4}
            wrap={false}
            align="middle"
            style={{
              position: 'sticky',
              top: 0,
              // 100%只能占用22个靶位的宽度，多余的宽度是 %
              minWidth: '100%',
              zIndex: 2,
            }}
          >
            <Col
              key={'target_header'}
              span={2}
              style={{
                position: 'sticky',
                left: 0,
                top: 0,
                zIndex: 1,
                background: 'white',
              }}
            >
              <Popconfirm
                disabled={!isTargetsSettingEnabled}
                placement="rightTop"
                autoAdjustOverflow={false}
                icon={null}
                title={
                  <InputNumber
                    style={{ minWidth: '150px' }}
                    id="TargetSettingTableHeaderPopConfirmInput"
                    placeholder="请输入靶位数量"
                    min={1}
                    max={1000}
                  />
                }
                onConfirm={() => {
                  // 获取input的值
                  const value = document
                    .getElementById('TargetSettingTableHeaderPopConfirmInput')
                    ?.getAttribute('value');
                  const targetNums = generateTargetNums(Number(value));
                  onSetTargetNums(targetNums);
                }}
              >
                <div
                  id="TargetSettingTableHeaderButton"
                  className="TargetSettingTableHeaderButton"
                >
                  {`靶位(${targetNums.length})`}
                  <div className="TargetSettingTableHeaderButtonDescription">
                    点击编辑
                  </div>
                </div>
              </Popconfirm>
            </Col>
            {targetNums.map((item: any, index: number) => (
              <Col
                key={'target_header_' + index}
                className="TargetSettingTableHeaderItemContent"
                style={{
                  flex: `0 0 ${525 / 6 / Math.min(22, targetNums.length)}%`,
                }}
              >
                <div className="TargetSettingTableHeaderItem">{item}</div>
              </Col>
            ))}
            {isTargetsSettingEnabled && (
              <Col
                style={{
                  position: 'sticky',
                  right: 0,
                  zIndex: 1,
                  background: 'white',
                }}
              >
                <Button
                  disabled={!isTargetsSettingEnabled}
                  onClick={onClickAddTargetNums}
                  onFocus={(e) => {
                    e.currentTarget.blur();
                  }}
                  icon={<PlusOutlined />}
                />
              </Col>
            )}
          </Row>
          {segments.map((segment: any, segmentIndex: number) => {
            return (
              <SegmentCell
                key={`TargetSettingsTable_segmentCell_${segmentIndex}`}
                targetNums={targetNums}
                segment={segment}
                segmentIndex={segmentIndex}
                onInsertSegment={(segment, position) => {
                  onInsertSegment(
                    segment,
                    segmentIndex + (position === 'bottom' ? 1 : 0)
                  );
                }}
                onDeleteSegment={onDeleteSegment}
                onUpdateSegment={(segment: Segment) => {
                  onUpdateSegment(segment, segmentIndex);
                }}
              />
            );
          })}
        </div>
        {targetNums && targetNums.length > 0 && (
          <SegmentSelectionPopover
            trigger={['click']}
            visible={addRoundPopUpVisible}
            lastSegmentTime={lastSegmentTime}
            onVisibleChange={(visible: boolean) => {
              setAddRoundPopUpVisible(visible);
            }}
            onFinishPicker={(segment) => {
              const targets =
                segment.segmentType === 'competition'
                  ? targetNums?.map((targetNum: any) => {
                      return {
                        targetNum,
                      };
                    })
                  : undefined;

              onAddSegment({
                ...segment,
                targets,
              });
            }}
          >
            {isTargetsSettingEnabled ? (
              <Button
                className="TargetSettingTableFooterButton"
                type="primary"
                size="middle"
                onClick={() => setAddRoundPopUpVisible(true)}
              >
                <PlusOutlined />
                &nbsp;
                {'添加新一轮赛程'}
              </Button>
            ) : null}
          </SegmentSelectionPopover>
        )}
      </ConfigProvider>
    </>
  );
};

TargetSettings.displayName = 'TargetSettings';

export default TargetSettings;
