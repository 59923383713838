import React, { FC, useEffect, useState } from 'react';
import {
  Button,
  Popover,
  PopoverProps,
  DatePicker,
  TimePicker,
  Input,
  Form,
  Select,
} from 'antd';
import './index.less';

import { LeftOutlined } from '@ant-design/icons';
import { Segment, SegmentTypes, getRoundName } from '../../../redux';
import moment, { Moment } from 'moment';

interface SegmentSelectionPopoverProps extends PopoverProps {
  lastSegmentTime?: string;
  onFinishPicker?: (values: Segment) => void;
}

const SegmentSelectionPopover: React.FC<SegmentSelectionPopoverProps> = ({
  visible,
  trigger,
  lastSegmentTime,
  onVisibleChange,
  onFinishPicker,
  children,
}) => {
  const [roundIndex, setRoundIndex] = React.useState<SegmentTypes>();
  const [form] = Form.useForm();

  useEffect(() => {
    if (form && roundIndex) {
      form.setFieldValue('displayName', getRoundName(roundIndex));
    }
  }, [form, roundIndex]);

  const nextRound = (segmentType: SegmentTypes) => {
    setRoundIndex(segmentType);
  };

  const back = () => {
    setRoundIndex(undefined);
  };

  const secondRoundComponent = (
    <div className="SegmentPopover" id="secondRoundComponent">
      <Button
        onClick={() => back()}
        className="SegmentPopoverBack"
        type="text"
        icon={<LeftOutlined />}
      >
        返回
      </Button>
      <SegmentEditingView
        lastSegmentTime={lastSegmentTime}
        segment={{ segmentType: roundIndex || 'competition' } as Segment}
        onSubmit={(value) => {
          onFinishPicker && onFinishPicker(value);
          onVisibleChange && onVisibleChange(false);
          back();
        }}
      />
    </div>
  );

  const firstRoundComponent = (
    <div className="SegmentPopover" id="firstRoundComponent">
      <Button type="text" onClick={() => nextRound('preCompetitionPractice')}>
        <div className="SegmentPopoverItem">
          <div className="SegmentPopoverItemIcon">练</div>
          赛前练习
        </div>
      </Button>
      <Button type="text" onClick={() => nextRound('rest')}>
        <div className="SegmentPopoverItem">
          <div className="SegmentPopoverItemIcon">休</div>
          赛间休息
        </div>
      </Button>
      <Button type="text" onClick={() => nextRound('preCompetitionAdjustment')}>
        <div className="SegmentPopoverItem">
          <div className="SegmentPopoverItemIcon">调</div>
          靶位调整
        </div>
      </Button>
      <Button type="text" onClick={() => nextRound('competition')}>
        <div className="SegmentPopoverItem">
          <div className="SegmentPopoverItemIcon">赛</div>
          正式比赛
        </div>
      </Button>
      <Button type="text" onClick={() => nextRound('customization')}>
        <div className="SegmentPopoverItem">其他事件</div>
      </Button>
    </div>
  );

  return (
    <Popover
      zIndex={2001}
      visible={visible}
      trigger={trigger}
      onVisibleChange={onVisibleChange}
      afterVisibleChange={(visible) => {
        !visible &&
          setTimeout(() => {
            form?.resetFields();
            back();
          }, 500);
      }}
      content={
        <div className="Segment">
          {roundIndex === undefined ? (
            <div className="SegmentChild">{firstRoundComponent}</div>
          ) : (
            <div className="SegmentChild">{secondRoundComponent}</div>
          )}
        </div>
      }
    >
      {children}
    </Popover>
  );
};

export default SegmentSelectionPopover;

interface SegmentEditingViewProps {
  lastSegmentTime?: string;
  segment: Segment;
  onSubmit: (values: Segment) => void;
}
export const SegmentEditingView: React.FC<SegmentEditingViewProps> = ({
  lastSegmentTime,
  segment,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const { segmentType, startTime, endTime, displayName } = segment;
  const allTypes: SegmentTypes[] = [
    'preCompetitionPractice',
    'rest',
    'preCompetitionAdjustment',
    'competition',
    'customization',
  ];
  const segmentTypeOptions = allTypes.map((value, i) => {
    return { value, label: getRoundName(value) };
  });

  return (
    <Form
      className="SegmentPopoverForm"
      form={form}
      initialValues={{
        displayName: displayName || getRoundName(segmentType),
        segmentType: segmentType,
        date: startTime ? moment(startTime) : moment(),
        time:
          startTime && endTime
            ? [
                startTime ? moment(startTime) : moment(),
                endTime ? moment(endTime) : moment(),
              ]
            : undefined,
      }}
      onFinish={(values) => {
        let date = values.date;
        let startTime = new Date(
          date.year(),
          date.month(),
          date.date(),
          values.time[0].hour(),
          values.time[0].minute()
        ).toISOString();
        let endTime = new Date(
          date.year(),
          date.month(),
          date.date(),
          values.time[1].hour(),
          values.time[1].minute()
        ).toISOString();
        onSubmit &&
          onSubmit({
            ...segment,
            segmentType: values.segmentType,
            displayName: values.displayName,
            startTime,
            endTime,
          });
        form?.resetFields();
      }}
    >
      <Form.Item
        label="类型"
        name="segmentType"
        rules={[{ required: true, message: '请选择类型！' }]}
      >
        <Select
          dropdownStyle={{ zIndex: 2002 }}
          style={{ width: 120 }}
          onChange={(value) => {
            form.setFieldsValue({
              displayName: value === 'customization' ? '' : getRoundName(value),
            });
          }}
          options={segmentTypeOptions}
        />
      </Form.Item>
      <Form.Item
        label="名称"
        name="displayName"
        rules={[
          { required: true, message: '请输入显示名称！' },
          { max: 20, message: '长度不超过 20 个字符' },
        ]}
      >
        <Input />
      </Form.Item>

      <Form.Item
        label="日期"
        name="date"
        rules={[{ required: true, message: '请选择日期！' }]}
      >
        <DatePicker popupStyle={{ zIndex: 2002 }} />
      </Form.Item>
      <TimeRangePickerItem
        label="时间"
        name="time"
        rules={[
          { required: true, message: '请选择起始时间！' },
          {
            validator: (rule: any, value: any, callback: any) => {
              const [startTime, endTime] = value;
              if (!endTime.isAfter(startTime)) {
                return Promise.reject('结束时间必须晚于开始时间！');
              } else {
                return Promise.resolve();
              }
            },
          },
        ]}
        lastSegmentTime={lastSegmentTime}
      />
      <Form.Item style={{ textAlign: 'center', margin: 0 }}>
        <Button
          type="primary"
          htmlType="submit"
          block
          onClick={() => form.submit()}
        >
          确认
        </Button>
      </Form.Item>
    </Form>
  );
};

interface TimeRangePickerItemProps {
  label: string;
  name: string;
  rules?: any[];
  lastSegmentTime?: string;
}
const TimeRangePickerItem: FC<TimeRangePickerItemProps> = ({
  label,
  name,
  rules,
  lastSegmentTime,
}) => {
  const form = Form.useFormInstance();
  const [value, setValue] = useState<[Moment, Moment]>(
    form.getFieldValue(name)
      ? form.getFieldValue(name)
      : [
          lastSegmentTime ? moment(lastSegmentTime) : moment(),
          lastSegmentTime
            ? moment(lastSegmentTime).add(30, 'minutes')
            : moment().add(30, 'minutes'),
        ]
  );
  const onChange = (e: any) => {
    setValue(e);
  };

  useEffect(() => {
    form.setFieldValue(name, value);
  }, [form, name, value]);

  return (
    <Form.Item label={label} name={name} rules={rules}>
      <TimePicker.RangePicker
        popupStyle={{ zIndex: 2002 }}
        autoComplete="off"
        format="HH:mm"
        ranges={{
          十五分钟: [moment(value[0]), moment(value[0]).add(15, 'minutes')],
          三十分钟: [moment(value[0]), moment(value[0]).add(30, 'minutes')],
          四十五分钟: [moment(value[0]), moment(value[0]).add(45, 'minutes')],
          六十分钟: [moment(value[0]), moment(value[0]).add(60, 'minutes')],
          ...(lastSegmentTime
            ? {
                上轮结束起十五分钟: [
                  moment(lastSegmentTime),
                  moment(lastSegmentTime).add(15, 'minutes'),
                ],
                上轮结束起三十分钟: [
                  moment(lastSegmentTime),
                  moment(lastSegmentTime).add(30, 'minutes'),
                ],
                上轮结束起四十五分钟: [
                  moment(lastSegmentTime),
                  moment(lastSegmentTime).add(45, 'minutes'),
                ],
                上轮结束起六十分钟: [
                  moment(lastSegmentTime),
                  moment(lastSegmentTime).add(60, 'minutes'),
                ],
              }
            : undefined),
        }}
        inputReadOnly
        minuteStep={5}
        onChange={onChange}
        value={value}
      />
    </Form.Item>
  );
};
